import React from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { I18N_SUPPORT } from "../config/config";
import { useUserData } from './hooks/useUserData';
import { BrieflyLogo, BuildingIcon, CaretIcon, EmailIcon, LogOutIcon, PlugIcon, StarIcon, QuestionMarkIcon, WorldIcon } from "./Icons";
import AICreditsPopup from "./Reusable/AICreditsPopup";
import { logOut, isBrieflySuccess } from '../utils/genericUtils';


const TopNav = () => {
  const navigate = useNavigate();
  const [userData] = useUserData();
  const { t } = useTranslation();

  const {
    admin_user,
    admin_user_subscription_is_active,
    managed_users,
    managed_users_not_confirmed,
    subscription_is_active,
    subscription_plan,
  } = userData;
  const isTeamAccount = managed_users ? (managed_users.concat(managed_users_not_confirmed)).length > 0 : false;
  const isTeamMemberAccount = !!admin_user;
  const isProGivenForFree = subscription_plan === 'STANDARD_GIVEN_FOR_FREE' && !isTeamMemberAccount;

  return (
    <div id="top-nav" style={{
      background: 'linear-gradient(90deg, #B700AF 0%, #F16617 100%)',
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      <div>
        <BrieflyLogo style={{ margin: '6px 15px', marginTop: '10px' }} />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!isProGivenForFree && !userData.error && !subscription_is_active && !isTeamMemberAccount || (isTeamMemberAccount && !admin_user_subscription_is_active) ?
          <div id="free-ai-credits-left">
            {userData.briefly_ai_credits_left} free AI credits left
            <div className="ai-credits-help-container">
              <QuestionMarkIcon data-tooltip-id="tooltip-button-ai-credits" style={{ cursor: 'pointer', position: 'relative', top: 2, marginLeft: 3 }} />
              <AICreditsPopup />
            </div >

          </div > : ''}
        {
          !userData.error && !isProGivenForFree ?
            <div className="subscription-status badge" onClick={() => navigate(`/settings/subscriptions`)} style={{
              background: 'white',
              width: 'unset',
              color: '#F97238',
              padding: '0 5px',
              display: 'flex',
              alignItems: 'center',
              marginRight: 10,
              cursor: 'pointer'
            }}>
              <StarIcon style={{ position: 'relative', marginRight: 3, marginLeft: 3 }} color="#F97238" />
              {!(subscription_is_active || (isTeamMemberAccount && admin_user_subscription_is_active)) ? 'UPGRADE TO PRO' : isTeamAccount || isTeamMemberAccount ? 'TEAM' : 'PRO'}
            </div > : ''
        }
        <div className="user-dropdown">
          {userData?.email && <>
            <div className="label">
              {userData.email}
              <CaretIcon style={{ transform: "rotate(180deg)" }} />
            </div >

            <div className="user-dropdown-content">

              {isBrieflySuccess() ? <>

              </> : <>
                <h6>{t('user-dropdown.settings.title', 'Settings')}</h6>

                <div onClick={() => navigate('/settings/ai-summary-email')} className="clickable">
                  <EmailIcon />
                  {t('user-dropdown.settings.email', 'AI Summary Email')}
                </div >

                {
                  userData.features?.manage_subscription && (
                    <div onClick={() => navigate('/settings/subscriptions')} className="clickable">
                      < StarIcon size={16} color="white" />
                      {t('user-dropdown.settings.subscription', 'Subscription')}
                    </div >
                  )}

                {
                  userData.features?.integrations && (
                    <div onClick={() => navigate('/settings/integrations')} className="clickable">
                      <PlugIcon />
                      {t('user-dropdown.settings.integrations', 'Integrations')}
                    </div>
                  )}

                {userData.administering_organization && (
                  <div onClick={() => navigate('/settings/organization')} className="clickable">
                    <BuildingIcon size={16} style={{width: '16px'}} />
                    {t('user-dropdown.settings.manage-org', 'Manage Organization')}
                  </div>
                )}

                {I18N_SUPPORT && (
                  <div onClick={() => navigate('/settings/language')} className="clickable">
                    <WorldIcon size={15} color='white' style={{ marginTop: 1 }} />
                    {t('user-dropdown.settings.language', 'Language')}
                  </div>
                )}

                <hr />
              </>}


              <div className="clickable"
                onClick={() => window.open('https://www.brieflyai.com/terms-of-service', '_blank')}>
                {t('user-dropdown.terms-and-conditions', 'Terms and Conditions')}
              </div>

              <div onClick={() => logOut(true)} className="clickable">
                {t('user-dropdown.log-out', 'Log Out')}
                <LogOutIcon />
              </div >

            </div >
          </>}
        </div >
      </div >

    </div >
  );
};

export default TopNav;