import { useState, useRef, useEffect } from 'react';
import { useBrieflyStorage } from '../hooks/useBrieflyStorage';
import { useBackendStoragePromptsList, useBackendStorageMyPrompts } from '../hooks/useBackendStorage';
import { useDataFromEndpoint } from '../hooks/useDataFromEndpoint';
import {
  getUserAccessToken, getTranscriptionAsText, generatePromptOutput, copyToClipboard, trackEvent, highlightText, getContextStrings, checkIfCustomSummaryIsEligible,
} from '../../utils/genericUtils';
import { ThreeDots } from 'react-loader-spinner';
import { LoadingSvg } from '../../images/LoadingSvg';
import { Dropdown } from '../controls/Dropdown';
import { PromptTemplateList } from '../controls/PromptTemplateList';
import { Checkbox } from '../controls/Checkbox';
import { IconWithTimeoutReplaceOnClick } from '../../utils/IconWithTimeoutReplaceOnClick';
import { useUserData } from '../hooks/useUserData';
import { useTranslation } from 'react-i18next';
import { CheckmarkCircleIcon, CopyIcon, DoubleLeftArrowIcon, PlusIcon, XIcon } from '../Icons';

function generateEmptyPrompt() {
  return [{
    label: 'Empty prompt',
    prompt: '',
    isActive: true
  }];
}

export default function MeetingTabOutput({ meetingData, version, isGpt4, searchWords, setShowOutOfCreditsPopup }) {
  const { t } = useTranslation();

  const [{ subscription_is_active, admin_user_subscription_is_active, subscription_plan }, forceRenewUserData] = useUserData();

  const subscriptionIsActive = subscription_is_active || admin_user_subscription_is_active || subscription_plan === 'STANDARD_GIVEN_FOR_FREE';

  const [isBrieflyTemplatesSelected, setIsBrieflyTemplatesSelected] = useState(false);

  const DROPDOWN_ITEMS = [
    {
      label: t('meeting.create-tab.prompt-templates.briefly-templates', 'Briefly templates'),
      onSelect: () => setIsBrieflyTemplatesSelected(true)
    },
    {
      label: t('meeting.create-tab.prompt-templates.saved-templates', 'My saved templates'),
      onSelect: () => setIsBrieflyTemplatesSelected(false)
    }
  ];
  const currentDropdownItem = DROPDOWN_ITEMS[isBrieflyTemplatesSelected ? 0 : 1];

  const [_localStoragePromptsList, _setLocalStoragePromptsList, localStoragePromptsListIsLoading] = useBrieflyStorage({
    storageKey: meetingData.meetingId + '-customised-prompts',
    defaultValue: generateEmptyPrompt(),
    processOnInitialLoad: list => list.length > 0 ? list.map(x => ({ ...x, isLoading: false })) : generateEmptyPrompt()
  });

  const [localStorageMyPrompts, setLocalStorageMyPrompts, localStorageMyPromptsIsLoading] = useBrieflyStorage({
    storageKey: 'briefly-my-prompts',
    defaultValue: [],
    delay: true,
    processOnInitialLoad: list => list.map(x => typeof x === 'string' ? { prompt: x, label: x } : x)
  });

  const [promptsList, setPromptsList, promptsListIsLoading] = useBackendStoragePromptsList({
    defaultValue: generateEmptyPrompt(),
    transcriptId: meetingData.meetingId,
    processOnInitialLoad: list => list.length > 0 ? list.map(x => ({ ...x, isLoading: false })) : generateEmptyPrompt()
  });

  const [myPrompts, setMyPrompts, myPromptsIsLoading] = useBackendStorageMyPrompts({
    defaultValue: [],
    delay: true,
    processOnInitialLoad: list => list.map(x => typeof x === 'string' ? { prompt: x, label: x } : x)
  });

  const isLoading = localStoragePromptsListIsLoading || localStorageMyPromptsIsLoading || promptsListIsLoading || myPromptsIsLoading;

  const isLoaded = !isLoading;

  useEffect(() => {
    if (isLoaded) {
      // Migrate local storage my prompts to backend

      if (localStorageMyPrompts.length > 0) {
        setMyPrompts(myPrompts.concat(localStorageMyPrompts));

        trackEvent("starting-my-prompts-local-to-backend-migration", {
          "localStorageMyPrompts": localStorageMyPrompts
        });

        // Mark as migrated
        setLocalStorageMyPrompts([]);

        window.location.reload();
      }

      // TODO Migrate local storage transcript prompts to backend?
    }
  }, [isLoaded]);

  const [brieflyRecommendedPrompts] = useDataFromEndpoint(
    '/api/briefly/briefly_recommended_prompts'
  );

  const [generatedResultBuffer, setGeneratedResultBuffer] = useState(null);

  const [isLeftCollapseOpen, setIsLeftCollapseOpen] = useState(false);
  const [isRightCollapseOpen, setIsRightCollapseOpen] = useState(true);

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const [isMyPromptNamingMode, setIsMyPromptNamingMode] = useState(false);
  const [promptNameValue, setPromptNameValue] = useState("");

  useEffect(() => {
    if (generatedResultBuffer) {
      const { result, originalPrompt, error } = generatedResultBuffer;


      if (error) {
        setPromptsList(prev => (prev || promptsList).map(x => x.prompt == originalPrompt.prompt ? { ...x, output: null, isLoading: false, error: true } : x));
      } else {
        setPromptsList(prev => (prev || promptsList).map(x => x.prompt == originalPrompt.prompt ? { ...x, output: result, isLoading: false, error: false, originalPrompt: originalPrompt.prompt } : x));
      }

    }
  }, [generatedResultBuffer]);

  const currentPrompt = promptsList?.find(x => x.isActive) || promptsList?.[0];

  const newOutputBtnIsActive = !!currentPrompt?.prompt;

  useEffect(() => {
    if (searchWords) {
      setTimeout(() => {
        if (!promptsListIsLoading) {
          var promptWithSearchResult = promptsList.find(x => getContextStrings(x.output, searchWords, 1).length > 0);
          setCurrentPrompt(promptWithSearchResult);
        }
      }, 100);
    }
  }, [searchWords]);

  function setCurrentPrompt(prompt, newPromptList = null) {
    setPromptsList(
      (newPromptList ? newPromptList : promptsList).map(x => x == prompt ? {
        ...x,
        isActive: true
      } : {
        ...x,
        isActive: false
      })
    );
  }

  const promptTextarea = useRef(null);

  function updateCurrentPrompt(newValue) {

    const isAutoGeneratedLabel = currentPrompt.label === 'Empty prompt' || currentPrompt.prompt.startsWith(currentPrompt.label.replace("...", ""));

    const newPrompt = {
      prompt: newValue,
      label: !isAutoGeneratedLabel ? currentPrompt.label : !newValue ? 'Empty prompt' : newValue.slice(0, 25) + (newValue.length > 25 ? '...' : ''),
      isActive: true,
    };

    if (currentPrompt.output) {
      newPrompt.output = currentPrompt.output;
      newPrompt.originalPrompt = currentPrompt.originalPrompt;
    }

    setPromptsList(promptsList.map(item => item == currentPrompt ? newPrompt : { ...item, isActive: false }));
  }

  function createNewPrompt() {
    trackEvent("briefly-create-create-new-prompt", {
      "total-prompts-open-before-create": promptsList.length
    });

    if (promptsList.find(x => x.prompt === '')) {
      setCurrentPrompt(promptsList.find(x => x.prompt === ''));
      return;
    }
    const newPrompt = {
      label: 'Empty prompt',
      prompt: '',
      isActive: true
    };
    setPromptsList(promptsList.map(x => ({ ...x, isActive: false })).concat([newPrompt]));
  }

  function generateOutput() {

    checkIfCustomSummaryIsEligible(getTranscriptionAsText(meetingData), currentPrompt.prompt, isEligible => {
      if (isEligible) {
        trackEvent("briefly-create-generate-output", {
          "prompt": currentPrompt.prompt
        });

        const promptBeingProcessedLoadingState = {
          ...currentPrompt,
          isLoading: true,
          error: false
        };

        setPromptsList(promptsList.map(item => item == currentPrompt ? promptBeingProcessedLoadingState : item));

        getUserAccessToken(({ access }) => {
          generatePromptOutput(meetingData, promptBeingProcessedLoadingState, access, version, isGpt4).then(response => {
            forceRenewUserData();
            setGeneratedResultBuffer({
              originalPrompt: promptBeingProcessedLoadingState,
              result: response.result,
              error: response.error
            });
          });
        });
      } else {
        setShowOutOfCreditsPopup(true);
      }
    });


  }

  if (isLoading) {
    return '';
  }

  const generateButtonIsActive = currentPrompt?.prompt && (!currentPrompt?.output || (currentPrompt?.originalPrompt !== currentPrompt?.prompt));

  const brieflyRecommendedPromptsInBottom = brieflyRecommendedPrompts?.prompts?.filter(p => p.show_in_bottom_panel);

  const isTheLastPrompt = promptsList.length <= 1;

  const isFirstOutputGenerated = promptsList.length > 1 || !!currentPrompt?.output;


  return (
    <div id="meeting-tab-output-container" style={{ width: '100%', maxWidth: '100%' }}>
      <div id="meeting-tab-output" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div id="prompts-list" style={{ display: isFirstOutputGenerated ? '' : 'none', width: isLeftCollapseOpen ? 168 : 45, minWidth: isLeftCollapseOpen ? 168 : 45, padding: 16, borderRight: '1px solid #212121' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {isLeftCollapseOpen && (
              <h3 style={{ margin: 0, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "18px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>
                AI Outputs
              </h3>
            )}
            <button
              id="hide-past-calls"
              style={{ width: 50, marginTop: 0 }}
              onClick={() => setIsLeftCollapseOpen(!isLeftCollapseOpen)}
            >
              {isLeftCollapseOpen ? 'Hide' : ''}
              <DoubleLeftArrowIcon style={{ transform: isLeftCollapseOpen ? '' : 'rotate(180deg)', left: isLeftCollapseOpen ? 5 : 0 }} />
            </button>
          </div>
          <button className="inline" onClick={createNewPrompt} style={{ height: 40, width: isLeftCollapseOpen ? '' : 40, padding: 10, background: 'transparent', border: '2px solid #E44867', display: 'flex', alignItems: 'center', justifyContent: 'center' }} disabled={!newOutputBtnIsActive}>
            <PlusIcon style={{ position: 'relative', left: isLeftCollapseOpen ? -5 : 0, top: 0 }} />
            {isLeftCollapseOpen ? 'New output' : ''}
          </button>

          {isLeftCollapseOpen && (
            <PromptTemplateList
              items={promptsList}
              isEditable={true}
              isDeletable={!isTheLastPrompt}
              onDelete={prompt => setPromptsList(promptsList.filter(x => x !== prompt))}
              currentItem={promptsList.find(x => x.prompt === currentPrompt.prompt)}
              onEdit={(prompt, newLabel) => {
                if (!isBrieflyTemplatesSelected) {
                  setPromptsList(promptsList.map(x => x.label === prompt.label ? { ...x, label: newLabel, } : x));
                }
              }}
              onClick={item => {
                const { prompt } = item;
                if (!currentPrompt.prompt) {
                  updateCurrentPrompt(prompt);
                } if (promptsList.find(x => x.prompt == prompt)) {
                  setCurrentPrompt(promptsList.find(x => x.prompt == prompt));
                } else {
                  const newPrompt = { prompt: prompt, output: null, isLoading: false, error: false, label: prompt.slice(0, 25) + (prompt.length > 25 ? '...' : '') };
                  if (currentPrompt.output) {
                    newPrompt.output = currentPrompt.output;
                    newPrompt.originalPrompt = currentPrompt.originalPrompt;
                  }
                  setCurrentPrompt(newPrompt, promptsList.concat([newPrompt]));
                }
                setIsMyPromptNamingMode(false);
              }}
            />
          )}
        </div>

        <div id="input-output-container" style={{ marginLeft: 20, marginRight: 20, maxWidth: 'unset', maxHeight: '100%', overflowY: 'auto', height: 'calc(100% - 20px)' }}>
          <div>
            <div className={"textarea-container " + (brieflyRecommendedPrompts?.prompts?.length > 0 && 'recommended-prompts-after')}>
              <textarea className="textarea" maxLength="1200" disabled={currentPrompt.isLoading} value={currentPrompt.prompt} onChange={e => updateCurrentPrompt(e.target.value)} ref={promptTextarea} placeholder={t('meeting.create-tab.prompt.placeholder', 'Enter your custom prompt to query your transcript ... Try something like "Turn this product brainstorm meeting into a Product Requirement Doc" or use one of Briefly custom made templates from the library.')} />
            </div>
            <div style={{ display: 'flex', marginTop: 5 }}>
              <div className="checkbox-container" style={{ flex: 3, paddingTop: 15 }} >
                {!isMyPromptNamingMode ?
                  (
                    <Checkbox
                      style={!currentPrompt.prompt || brieflyRecommendedPrompts?.prompts?.find(x => x.prompt === currentPrompt.prompt) ?
                        { fontSize: 14, pointerEvents: 'none', opacity: 0.7 } :
                        { fontSize: 14 }
                      }
                      isChecked={myPrompts?.find(x => x.prompt === currentPrompt.prompt) || brieflyRecommendedPrompts?.prompts?.find(p => p.prompt == currentPrompt.prompt)}
                      label={t("meeting.create-tab.prompt.save-template", "Save this prompt template")}
                      setIsChecked={isChecked => {
                        if (isChecked) {
                          setIsMyPromptNamingMode(true);
                          setPromptNameValue("");
                        } else {
                          setMyPrompts(myPrompts.filter(p => p.prompt !== currentPrompt.prompt));
                        }
                      }
                      } />
                  ) : (
                    <div className="my-prompt-naming-new">
                      <div className="svg" onClick={() => setIsMyPromptNamingMode(false)}>
                        <XIcon size={12} />
                      </div>
                      <input
                        type="text"
                        placeholder={t("meeting.create-tab.prompt.name-template.placeholder", "Name Your Prompt")}
                        value={promptNameValue}
                        onChange={e => setPromptNameValue(e.target.value)}
                      />
                      <button
                        disabled={!promptNameValue}
                        onClick={() => {
                          trackEvent("briefly-create-save-prompt", {
                            "prompt": currentPrompt.prompt,
                            "label": promptNameValue
                          });

                          setMyPrompts(myPrompts.concat([{ prompt: currentPrompt.prompt, label: promptNameValue }]));

                          setIsMyPromptNamingMode(false);
                        }}>
                        {t('save', { defaultValue: 'Save', ns: 'Actions' })}
                      </button>
                    </div>
                  )}
              </div>

              <button disabled={!generateButtonIsActive} onClick={generateOutput} style={{ marginTop: 0, marginLeft: 16, flex: 2 }}>
                {currentPrompt.isLoading ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ThreeDots
                    height="20"
                    width="20"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div> : <>
                  {t('meeting.create-tab.prompt.generate', 'Generate output')} {!subscriptionIsActive ? '[1 AI Credit]' : ''}
                </>}
              </button>
            </div>
          </div>

          {(() => {
            const { isLoading, output, error } = currentPrompt;
            return <div id="ai-output" className={"gradient-border-container " + (currentPrompt.error && 'error ') + (output && ' loaded')}>
              <div className="gradient-border" />
              <div className="gradient-content">
                <h5>
                  <div>
                    <span className="copy-btn-container" style={output ? {} : { width: 0 }}>
                      {output ? (
                        <IconWithTimeoutReplaceOnClick
                          onClick={() => {
                            trackEvent("briefly-create-copy-ai-output", {
                              "prompt": currentPrompt.prompt,
                              "output": output
                            });
                            copyToClipboard(output);
                          }}
                          initialIcon={<CopyIcon color="#E44867" style={{ cursor: 'pointer' }} />}
                          clickedIcon={<CheckmarkCircleIcon color="#E44867" />} />
                      ) : (
                        <span></span>
                      )}
                    </span>
                    <span>{t('meeting.create-tab.output.title', 'AI Output')}</span>
                  </div>

                </h5>
                <p style={{ minHeight: 210 }}>
                  {isLoading ? <div className="output-loader">
                    <LoadingSvg />
                    <div className="loader-container">
                      <div className="loader-inner" />
                    </div>
                    <h3>{t('meeting.create-tab.output.processing.heading', 'Please wait while we create your AI output...')}</h3>
                    <div className="typewriter-container">
                      <div className="typewriter line-1">
                        <div>{t('meeting.create-tab.output.processing.line1', 'Analyzing your transcript...')}</div>
                      </div>
                      <div className="typewriter line-2">
                        <div>{t('meeting.create-tab.output.processing.line2', 'Processing the information...')}</div>
                      </div>
                      <div className="typewriter line-3">
                        <div>{t('meeting.create-tab.output.processing.line3', 'Thinking...')}</div>
                      </div>
                      <div className="typewriter line-4">
                        <div>{t('meeting.create-tab.output.processing.line4', 'Generating results...')}</div>
                      </div>
                      <div className="typewriter line-5">
                        <div>{t("meeting.create-tab.output.processing.line5", "It's taking longer than usual. We're still working on it...")}</div>
                      </div>
                    </div>
                  </div>
                    : output ? output.split('\n').map((x, i) => <div key={i}>{searchWords ? highlightText(x, searchWords, { background: '#F9C338', color: 'black' }) : x}</div>) : error ? <>
                      <div className="error">{t('meeting.create-tab.output.error', 'There was an issue with OpenAI while generating your output, try again later...')}</div>
                    </> : <span style={{ opacity: 0.5 }}>{t('meeting.create-tab.output.start', 'Create or choose a prompt to generate your personalized AI output...')}</span>}
                </p>
              </div>
            </div>;
          })()}


        </div>
        <div id="ai-output-list" style={{ width: isRightCollapseOpen ? 268 : 45, minWidth: isRightCollapseOpen ? 268 : 45, padding: 16, borderLeft: '1px solid #212121' }} className={'_' + Math.min(promptsList.length, 7) + '-prompts'}>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>

            {isRightCollapseOpen ?
              <h3 style={{ margin: 0, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "18px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal", marginBottom: 12 }}>
                {t('meeting.create-tab.prompt-templates.title', 'Prompt library')}
              </h3> :
              ''}

            <button id="hide-past-calls" style={{ marginTop: 0 }} onClick={() => setIsRightCollapseOpen(!isRightCollapseOpen)}>
              {isRightCollapseOpen ? t('hide', { defaultValue: 'Hide', ns: 'Actions' }) : ''}
              <DoubleLeftArrowIcon color="#C6C6C6" style={{ transform: !isRightCollapseOpen ? '' : 'rotate(180deg)', left: isRightCollapseOpen ? 5 : 0 }} />
            </button>
          </div>

          {isRightCollapseOpen && <Dropdown isOpen={dropdownIsOpen} setIsOpen={setDropdownIsOpen} isBrieflyTemplatesSelected={isBrieflyTemplatesSelected} items={DROPDOWN_ITEMS} selectedItem={currentDropdownItem}
          />}

          {isRightCollapseOpen && brieflyRecommendedPromptsInBottom?.length > 0 && <PromptTemplateList
            items={(isBrieflyTemplatesSelected ? brieflyRecommendedPromptsInBottom : myPrompts)}
            isEditable={!isBrieflyTemplatesSelected}
            isDeletable={!isBrieflyTemplatesSelected}
            onDelete={prompt => setMyPrompts(myPrompts.filter(x => x !== prompt))}
            currentItem={(isBrieflyTemplatesSelected ? brieflyRecommendedPromptsInBottom : myPrompts).find(x => x.prompt === currentPrompt.prompt)}
            onEdit={(prompt, newLabel) => {
              if (!isBrieflyTemplatesSelected) {
                setMyPrompts(myPrompts.map(x => x.label === prompt.label ? { ...x, label: newLabel, } : x));
              }
            }}
            onClick={item => {
              const { prompt, label } = item;

              trackEvent("briefly-create-use-saved-prompt", {
                "prompt": prompt,
                "label": label,
                "source": "BOTTOM_PANEL"
              });
              if (!currentPrompt.prompt) {
                updateCurrentPrompt(prompt);
              } if (promptsList.find(x => x.prompt == prompt)) {
                setCurrentPrompt(promptsList.find(x => x.prompt == prompt));
              } else {
                updateCurrentPrompt(prompt);
              }
              setIsMyPromptNamingMode(false);
            }}
          />}
        </div>
      </div>
    </div>
  );
}
