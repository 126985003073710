import { authCalendar, trackEvent } from "../utils/genericUtils";
import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import useUserData from "./hooks/useUserData";
import React, { useState } from "react";
import BackIcon from "../images/back.svg";
import Checkmark from "../images/checkmark.svg";

import ZoomOnboardingPopup from "./ZoomOnboardingPopup";
import SlackOnboardingPopup from "./SlackOnboardingPopup";
import HubspotOnboardingPopup from "./HubSpotOnboardingPopup";
import SalesForceOnboardingPopup from "./SalesForceOnboardingPopup";
import { useGoogleLogin } from "@react-oauth/google";
import TopNav from "./TopNav";
import { GoogleCalendarLogo, GoogleMeetLogo, HubspotLogo, SalesforceLogo, SlackLogo, ZoomLogo } from "./Icons";

const OnboardingConnecting = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const apps = searchParams.get('usingApps').split(',');
  const useSlack = searchParams.get('usingSlack') === 'true';
  const crmApps = searchParams.get('usingCRMs').split(',');
  const [userData, forceRenewUserData] = useUserData();
  const [showZoomOnboardingPopup, setShowZoomOnboardingPopup] = useState(false);
  const [showSlackOnboardingPopup, setShowSlackOnboardingPopup] = useState(false);
  const [showHubSpotOnboardingPopup, setShowHubSpotOnboardingPopup] = useState(false);
  const [showSalesforceOnboardingPopup, setShowSalesforceOnboardingPopup] = useState(false);

  const { brieflyIsInstalled, } = useLoaderData();
  const {
    is_integration_active_google_calendar,
    is_integration_active_salesforce,
    slack_workspace_name,
    salesforce_redshift_db_name,
    email_google_calendar,
    is_integration_active_hubspot,
    zoom_is_setup
  } = userData;
  const salesforceDomain = salesforce_redshift_db_name ? salesforce_redshift_db_name?.replace('staging-salesforce-', '')?.replace('prod-salesforce-', '')?.replaceAll('-', '.') : '';


  const handleRedirect = () => {
    navigate('/onboarding/finalize');
  };

  function onCalendarLoginSuccess(data) {
    authCalendar(data.code, calendarData => {
      setTimeout(() => {
        forceRenewUserData();
      }, 500);
    });
  }

  const calendarLogin = useGoogleLogin({
    onSuccess: codeResponse => onCalendarLoginSuccess(codeResponse),
    flow: 'auth-code',
    scope: "openid profile email https://www.googleapis.com/auth/calendar.readonly"
  });
  const handleActiveCalendar = () => {
    trackEvent('briefly-connect-calendar-clicked', {});
    calendarLogin();
  };

  const handleInstallExtension = () => {
    window.open('https://chrome.google.com/webstore/detail/briefly-ai-meeting-summar/bjmgcelbpkgmofiogkmleblcmecflldk', '_blank');
  };

  return (
    <div style={{
      background: '#141414',
      minHeight: '100vh',
      width: '100%',
      position: 'relative'

    }}>
      <TopNav />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: 'column',
          maxWidth: 600,
          width: '100%',
          margin: 'auto',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }}>
        <a href={'/onboarding'} style={{
          width: '100%',
          textDecoration: 'none',
          marginBottom: 24,
          color: 'white',
          fontSize: 14,
          fontFamily: 'Roboto',
          fontWeight: '500',
          letterSpacing: 0.15,
          wordWrap: 'break-word',
          display: 'flex',
          alignItems: 'center',
          gap: 8,
        }}>
          <img
            style={{
              width: 16,
              height: 16,
            }}
            src={BackIcon}
          />
          Back
        </a>

        <div style={{
          padding: 24,
          background: '#212121',
          borderRadius: 4,
          border: '1px #6D6D6D solid',
          gap: 16,
          display: 'flex',
          width: '100%',
          boxSizing: 'border-box',
          flexDirection: 'column',
        }}>
          <div>
            <p style={{
              width: '100%',
              color: 'white',
              fontSize: 20,
              fontFamily: 'Source Sans Pro',
              margin: 0,
              marginBottom: 10,
              fontWeight: '900',
              wordWrap: 'break-word'
            }}>Meeting App integrations
            </p>
          </div>
          {/* GOOGLE MEET*/}
          {apps.find(e => e === 'Google Meet') &&
            <>
              <div style={{
                display: 'flex',
                alignItems: "center",
              }}>
                <div style={{
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                  flex: 1
                }}>
                  <GoogleMeetLogo width="24" />
                  <div style={{ flex: 1, gap: 8, flexDirection: 'column', display: 'flex' }}>
                    <p style={{
                      color: 'white',
                      fontSize: 16,
                      fontFamily: 'Roboto',
                      fontWeight: '500',
                      letterSpacing: 0.15,
                      margin: 0,
                      wordWrap: 'break-word'
                    }}>Briefly for Google Meet
                      {brieflyIsInstalled &&
                        <span style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          gap: 2,
                          marginLeft: 8,
                          fontSize: 12,
                          fontFamily: 'Roboto',
                          fontWeight: '400',
                          letterSpacing: 0.30,
                          wordWrap: 'break-word'
                        }}>
                          <img src={Checkmark} style={{ width: 12, height: 12 }} />
                          email@domain.com
                        </span>}
                    </p>
                    <p style={{
                      width: '100%',
                      color: '#C6C6C6',
                      fontSize: 12,
                      fontFamily: 'Roboto',
                      fontWeight: '400',
                      margin: 0,
                      letterSpacing: 0.30,
                      wordWrap: 'break-word'
                    }}>Download the Chrome Extension to use Briefly in Google Meet
                    </p>
                  </div>
                </div>
                {
                  brieflyIsInstalled ?
                    <p style={{
                      textAlign: 'right',
                      color: 'white',
                      fontSize: 14,
                      fontFamily: 'Roboto',
                      fontWeight: '500',
                      letterSpacing: 0.15,
                      wordWrap: 'break-word',
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 8,
                    }}>
                      Extension Installed
                      <img src={Checkmark} style={{ width: 16, height: 16 }} />
                    </p>
                    :
                    <button
                      onClick={handleInstallExtension}
                      style={{
                        height: 'fit-content',
                        padding: '8px 16px',
                        width: 'fit-content', textWrap: 'nowrap',
                      }}>
                      Add to Chrome
                    </button>}
              </div>
              <hr style={{ background: '#434343', width: '100%' }} />
            </>}
          {/* ZOOM */}
          {apps.find(e => e === 'Zoom') && <div style={{
            display: 'flex',
            alignItems: "center",
          }}>
            <div style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
              flex: 1
            }}>
              <ZoomLogo size="24" />
              <div style={{ flex: 1, gap: 8, flexDirection: 'column', display: 'flex' }}>
                <p style={{
                  color: 'white',
                  fontSize: 16,
                  fontFamily: 'Roboto',
                  fontWeight: '500',
                  letterSpacing: 0.15,
                  margin: 0,
                  wordWrap: 'break-word'
                }}>Briefly for Zoom
                </p>
                <p style={{
                  width: '100%',
                  color: '#C6C6C6',
                  fontSize: 12,
                  fontFamily: 'Roboto',
                  fontWeight: '400',
                  margin: 0,
                  letterSpacing: 0.30,
                  wordWrap: 'break-word'
                }}>Set up Briefly note-taker for Zoom
                </p>
              </div>
            </div>
            {zoom_is_setup ?
              <p style={{
                textAlign: 'right',
                color: 'white',
                fontSize: 14,
                fontFamily: 'Roboto',
                fontWeight: '500',
                letterSpacing: 0.15,
                wordWrap: 'break-word',
                display: 'inline-flex',
                alignItems: 'center',
                gap: 8,
              }}>
                Connected
                <img src={Checkmark} style={{ width: 16, height: 16 }} />
              </p>
              : <button
                onClick={() => {
                  setShowZoomOnboardingPopup(true);

                }}
                style={{
                  height: 'fit-content',
                  padding: '8px 32px',
                  width: 'fit-content', textWrap: 'nowrap',
                }}>
                Setup
              </button>}
          </div>}
          {/*TOOLS */}
          <div>
            <p style={{
              width: '100%',
              color: 'white',
              fontSize: 20,
              fontFamily: 'Source Sans Pro',
              margin: 0,
              marginBottom: 10,
              fontWeight: '900',

              wordWrap: 'break-word'
            }}>Connect your tools
            </p>
          </div>
          {/* GOOGLE CALENDER*/}
          <div style={{

            display: "flex",
            alignItems: "center",
          }}>
            <div style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
              flex: 1
            }}>
              <GoogleCalendarLogo size="24" />
              <div style={{ flex: 1, gap: 8, flexDirection: 'column', display: 'flex' }}>
                <p style={{
                  color: 'white',
                  fontSize: 16,
                  fontFamily: 'Roboto',
                  fontWeight: '500',
                  letterSpacing: 0.15,
                  margin: 0,
                  wordWrap: 'break-word'
                }}>Connect Google Calendar
                  {is_integration_active_google_calendar &&
                    <span style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 2,
                      marginLeft: 8,
                      fontSize: 12,
                      fontFamily: 'Roboto',
                      fontWeight: '400',
                      letterSpacing: 0.30,
                      wordWrap: 'break-word'
                    }}>
                      <img src={Checkmark} style={{ width: 12, height: 12 }} />
                      {email_google_calendar}
                    </span>}
                </p>
                <p style={{
                  width: '100%',
                  color: '#C6C6C6',
                  fontSize: 12,
                  fontFamily: 'Roboto',
                  fontWeight: '400',
                  margin: 0,
                  letterSpacing: 0.30,
                  wordWrap: 'break-word'
                }}>Improve your Briefly experience with call data
                </p>
              </div>
            </div>
            {is_integration_active_google_calendar ?
              <p style={{
                textAlign: 'right',
                color: 'white',
                fontSize: 14,
                fontFamily: 'Roboto',
                fontWeight: '500',
                letterSpacing: 0.15,
                wordWrap: 'break-word',
                display: 'inline-flex',
                alignItems: 'center',
                gap: 8,
              }}>
                Connected
                <img src={Checkmark} style={{ width: 16, height: 16 }} />
              </p>
              :
              <button
                onClick={handleActiveCalendar}
                style={{
                  height: 'fit-content',
                  margin: 0,
                  padding: '8px 32px',
                  width: 'fit-content', textWrap: 'nowrap',
                }}>
                Connect
              </button>}
          </div>
          {
            useSlack && <>
              <hr style={{ background: '#434343', width: '100%' }} />
              {/* SLACK */}
              <div style={{
                display: "flex",
                alignItems: "center",
              }}>
                <div style={{
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                  flex: 1
                }}>
                  <SlackLogo width="24" />
                  <div style={{ flex: 1, gap: 8, flexDirection: 'column', display: 'flex' }}>
                    <div style={{
                      display: 'flex',
                      gap: 8,
                      flexDirection: 'row',
                      alignItems: 'center',
                      textWrap: 'nowrap'
                    }}>
                      <p style={{
                        color: 'white',
                        fontSize: 16,
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        letterSpacing: 0.15,
                        margin: 0,
                        wordWrap: 'break-word'
                      }}>Connect Slack
                      </p>
                      {
                        slack_workspace_name ?
                          <span style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: 2,
                            marginLeft: 8,
                            color: '#fff',
                            fontSize: 12,
                            fontFamily: 'Roboto',
                            fontWeight: '400',
                            letterSpacing: 0.30,
                            wordWrap: 'break-word'
                          }}>
                            <img src={Checkmark} style={{ width: 12, height: 12 }} />
                            {slack_workspace_name}
                          </span>
                          :
                          <div style={{
                            width: 'fit-content',
                            height: '100%',
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 2,
                            paddingBottom: 2,
                            background: '#F97238',
                            borderRadius: 4,
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: 3,
                            display: 'inline-flex'
                          }}>
                            <div style={{
                              color: 'white',
                              fontSize: 12,
                              fontFamily: 'Roboto',
                              fontWeight: '700',
                              letterSpacing: 0.30,
                              wordWrap: 'break-word'
                            }}>PRO
                            </div>
                          </div>}
                    </div>
                    <p style={{
                      width: '100%',
                      color: '#C6C6C6',
                      fontSize: 12,
                      fontFamily: 'Roboto',
                      fontWeight: '400',
                      margin: 0,
                      letterSpacing: 0.30,
                      wordWrap: 'break-word'
                    }}>Receive AI summaries and account updates in Slack
                    </p>
                  </div>
                </div>
                {slack_workspace_name ?
                  <p style={{
                    textAlign: 'right',
                    color: 'white',
                    fontSize: 14,
                    fontFamily: 'Roboto',
                    fontWeight: '500',
                    letterSpacing: 0.15,
                    wordWrap: 'break-word',
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: 8,
                  }}>
                    Connected
                    <img src={Checkmark} style={{ width: 16, height: 16 }} />
                  </p>
                  :
                  <button

                    onClick={
                      () => {
                        setShowSlackOnboardingPopup(true);
                      }
                    }
                    style={{
                      height: 'fit-content',
                      margin: 0,
                      padding: '8px 32px',
                      width: 'fit-content', textWrap: 'nowrap',
                    }}>
                    Connect
                  </button>}
              </div>
            </>}
          {/* Salesforce */}
          {
            crmApps.find(e => e === 'Salesforce') && <>
              <hr style={{ background: '#434343', width: '100%' }} />
              <div style={{
                display: "flex",
                alignItems: "center",

              }}>
                <div style={{
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                  flex: 1
                }}>
                  <SalesforceLogo style={{ width: 24, height: 24 }} />
                  <div style={{ flex: 1, gap: 8, flexDirection: 'column', display: 'flex' }}>
                    <div style={{
                      display: 'flex',
                      gap: 8,
                      flexDirection: 'row',
                      alignItems: 'center',
                      textWrap: 'nowrap'
                    }}>
                      <p style={{
                        color: 'white',
                        fontSize: 16,
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        letterSpacing: 0.15,
                        margin: 0,
                        wordWrap: 'break-word'
                      }}>Connect Salesforce
                      </p>
                      {is_integration_active_salesforce ?
                        <span style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          gap: 2,
                          marginLeft: 8,
                          color: '#fff',
                          fontSize: 12,
                          fontFamily: 'Roboto',
                          fontWeight: '400',
                          letterSpacing: 0.30,
                          wordWrap: 'break-word'
                        }}>
                          <img src={Checkmark} style={{ width: 12, height: 12 }} />
                          {salesforceDomain}
                        </span>
                        :
                        <div style={{
                          width: 'fit-content',
                          height: '100%',
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 2,
                          paddingBottom: 2,
                          background: '#F97238',
                          borderRadius: 4,
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: 3,
                          display: 'inline-flex'
                        }}>
                          <div style={{
                            color: 'white',
                            fontSize: 12,
                            fontFamily: 'Roboto',
                            fontWeight: '700',
                            letterSpacing: 0.30,
                            wordWrap: 'break-word'
                          }}>PRO
                          </div>
                        </div>
                      }
                    </div>
                    <p style={{
                      width: '100%',
                      color: '#C6C6C6',
                      fontSize: 12,
                      fontFamily: 'Roboto',
                      fontWeight: '400',
                      margin: 0,
                      letterSpacing: 0.30,
                      wordWrap: 'break-word'
                    }}>Save transcripts and AI summaries to Salesforce
                    </p>
                  </div>
                </div>
                {
                  is_integration_active_salesforce ?
                    <p style={{
                      textAlign: 'right',
                      color: 'white',
                      fontSize: 14,
                      fontFamily: 'Roboto',
                      fontWeight: '500',
                      letterSpacing: 0.15,
                      wordWrap: 'break-word',
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 8,
                    }}>
                      Connected
                      <img src={Checkmark} style={{ width: 16, height: 16 }} />
                    </p>
                    : <button
                      onClick={
                        () => {
                          setShowSalesforceOnboardingPopup(true);
                        }
                      }
                      style={{
                        height: 'fit-content',
                        padding: '8px 32px',
                        margin: 0,
                        width: 'fit-content', textWrap: 'nowrap',
                      }}>
                      Connect
                    </button>
                }
              </div>
            </>
          }
          {/* Hubspot */}
          {
            crmApps.find(e => e === 'Hubspot') && <>
              <hr style={{ background: '#434343', width: '100%' }} />
              <div style={{
                display: "flex",
                alignItems: "center",

              }}>
                <div style={{
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                  flex: 1
                }}>
                  <HubspotLogo size="24" />
                  <div style={{ flex: 1, gap: 8, flexDirection: 'column', display: 'flex' }}>
                    <div style={{
                      display: 'flex',
                      gap: 8,
                      flexDirection: 'row',
                      alignItems: 'center',
                      textWrap: 'nowrap'
                    }}>
                      <p style={{
                        color: 'white',
                        fontSize: 16,
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        letterSpacing: 0.15,
                        margin: 0,
                        wordWrap: 'break-word'
                      }}>Connect Hubspot
                      </p>
                      {is_integration_active_hubspot ?
                        <span style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          gap: 2,
                          marginLeft: 8,
                          color: '#fff',
                          fontSize: 12,
                          fontFamily: 'Roboto',
                          fontWeight: '400',
                          letterSpacing: 0.30,
                          wordWrap: 'break-word'
                        }}>
                          <img src={Checkmark} style={{ width: 12, height: 12 }} />
                          {salesforceDomain}
                        </span>
                        :
                        <div style={{
                          width: 'fit-content',
                          height: '100%',
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 2,
                          paddingBottom: 2,
                          background: '#F97238',
                          borderRadius: 4,
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: 3,
                          display: 'inline-flex'
                        }}>
                          <div style={{
                            color: 'white',
                            fontSize: 12,
                            fontFamily: 'Roboto',
                            fontWeight: '700',
                            letterSpacing: 0.30,
                            wordWrap: 'break-word'
                          }}>PRO
                          </div>
                        </div>
                      }
                    </div>
                    <p style={{
                      width: '100%',
                      color: '#C6C6C6',
                      fontSize: 12,
                      fontFamily: 'Roboto',
                      fontWeight: '400',
                      margin: 0,
                      letterSpacing: 0.30,
                      wordWrap: 'break-word'
                    }}>Save transcripts and AI summaries to Hubspot
                    </p>
                  </div>
                </div>
                {
                  is_integration_active_hubspot ?
                    <p style={{
                      textAlign: 'right',
                      color: 'white',
                      fontSize: 14,
                      fontFamily: 'Roboto',
                      fontWeight: '500',
                      letterSpacing: 0.15,
                      wordWrap: 'break-word',
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 8,
                    }}>
                      Connected
                      <img src={Checkmark} style={{ width: 16, height: 16 }} />
                    </p>
                    : <button
                      onClick={
                        () => {
                          setShowHubSpotOnboardingPopup(true);
                        }
                      }
                      style={{
                        height: 'fit-content',
                        padding: '8px 32px',
                        margin: 0,
                        width: 'fit-content', textWrap: 'nowrap',
                      }}>
                      Connect
                    </button>
                }
              </div>
            </>
          }
        </div>
        <button onClick={handleRedirect}>
          Continue to Briefly settings
        </button>
      </div>
      {
        showZoomOnboardingPopup &&
        <ZoomOnboardingPopup
          onClose={() => setShowZoomOnboardingPopup(false)}
        />
      }
      {
        showSlackOnboardingPopup &&
        <SlackOnboardingPopup
          onClose={() => setShowSlackOnboardingPopup(false)}
        />
      }
      {
        showSalesforceOnboardingPopup &&
        <SalesForceOnboardingPopup
          onClose={() => setShowSalesforceOnboardingPopup(false)}
        />
      }
      {
        showHubSpotOnboardingPopup &&
        <HubspotOnboardingPopup
          onClose={() => setShowHubSpotOnboardingPopup(false)}
        />

      }
    </div >
  );
};


export default OnboardingConnecting;

